// import node module libraries
import { Col, Row } from "react-bootstrap";
import { Telephone, EnvelopeOpen, GeoAlt } from "react-bootstrap-icons";
import ContactForm from "./ContactForm";
import orangeOrbs from "../../assets/images/background/orangeOrbs.mp4";
import { useState, useEffect } from "react";
const Contact = () => {
  const [ios, setIos] = useState(true);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <div className="bg-black">
      <div
        style={{
          position: "relative",
          width: "100%",
          height: "100%",
          height: "fit-content",
          overflow: "hidden",
          minHeight: "100vh",
          alignContent: "center",
        }}>
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            overflow: "hidden",
          }}>
          <video
            autoPlay={!ios}
            muted
            loop
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              paddingTop: "100px",
              paddingBottom: "100px",
            }}>
            <source src={orangeOrbs} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
        <div
          style={{
            position: "relative",
            zIndex: "0",
            minHeight: "100vh",
            alignContent: "center",
            height: "100%",
            backgroundColor: "#00000079",
          }}>
          <Row className="">
            <Col
              style={{
                minHeight: "100vh",
                alignContent: "center",
                height: "100%",
              }}
              className="py-0"
              lg={6}
              md={12}
              sm={12}>
              <div className="px-xl-15 px-md-8 px-4 px-lg-3">
                <div>
                  <h1 className="display-5 fw-bold text-primary">
                    Get In Touch
                  </h1>
                  <p className="lead text-white">
                    Fill in the form to get in touch with someone on our team,
                    and we will reach out shortly.
                  </p>
                  <div className="mt-8">
                    <a href="tel:+18042344741" className="text-white">
                      <p className="fs-4">
                        <Telephone size={16} className="text-primary me-2" /> +1
                        804-234-4741
                      </p>
                    </a>
                    <a
                      href="mailto:inquiries@decentralized-ventures.com"
                      className="text-white">
                      <p className="fs-4">
                        <EnvelopeOpen size={16} className="text-primary me-2" />{" "}
                        inquiries@decentralized-ventures.com
                      </p>
                    </a>
                    <a
                      className="text-white"
                      href="https://goo.gl/maps/x6FCohwADAxwSgnM7"
                      target="_blank"
                      rel="noreferrer">
                      <p className="fs-4 mb-0">
                        <span
                          className="mb-0"
                          style={{
                            display: "inline-flex",
                            alignItems: "center",
                            height: "100%",
                          }}>
                          <GeoAlt size={16} className="text-primary me-2" />
                          P.O. Box 141
                        </span>
                      </p>
                      <p className="fs-4" style={{ marginLeft: "1.5rem" }}>
                        Powhatan, VA 23139
                      </p>
                    </a>
                  </div>
                </div>
              </div>
            </Col>

            {/* right side form section with background color */}
            <Col
              style={{
                minHeight: "100vh",
                alignContent: "center",
                height: "100%",
              }}
              lg={6}
              className="d-lg-flex align-items-center w-lg-50 position-fixed-lg top-0 right-0 mt-n15 mt-lg-0  py-lg-20">
              <ContactForm />
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Contact;
