// import node module libraries
import { BrowserRouter as Router } from "react-router-dom";
import "assets/scss/theme.scss";
import Nav from "components/navbar/NavbarDefault";
import Footer from "components/footer/FooterCenter";
import ScrollToTop from "common/ScrollToTop";
import AllRoutes from "routes/AllRoutes";

function App() {
  return (
    <Router>
      <div className="App">
        <ScrollToTop />
        <Nav />
        <AllRoutes />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
