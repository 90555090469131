import { Fragment, useEffect, useState } from "react";
import { Row } from "react-bootstrap";
import CourseCard from "./ProjectCard";
import { ProjectData } from "components/projects/AllProjectData";
import networkedWorld from "../../assets/images/background/networked-world.mp4";

const Projects = () => {
  const [ios, setIos] = useState(true);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={networkedWorld} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              zIndex: "0",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
              backgroundColor: "#00000079",
              paddingTop: "100px",
              paddingBottom: "100px",
            }}>
            <div style={{ position: "relative" }}>
              <h1 className="mb-1 text-dark fw-bold display-2 text-center pt-12 text-white">
                Our <span className="text-primary">Projects</span>
              </h1>
              <Row className="py-12 d-flex justify-content-center gap-5">
                {ProjectData.map((item, index) => (
                  <CourseCard item={item} />
                ))}
              </Row>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Projects;
