// import node module libraries
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState } from "react";
import Socials from "common/socials/Socials";

const FooterCenter = () => {
  const [year] = useState(new Date().getFullYear());

  return (
    <div className="py-lg-5 py-5 bg-black">
      <Container>
        <Row className="justify-content-center text-center  text-white">
          {/*  Desc  */}
          <Col lg={8} md={12} sm={12}>
            <div className="mb-3 d-flex justify-content-center">
              <Socials />
            </div>
          </Col>
          <Col lg={8} md={12} sm={12}>
            <span>
              {`© ${year} Decentralized Ventures LLC. All Rights
							Reserved`}
            </span>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FooterCenter;
