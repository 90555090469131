import FundingChain from "assets/images/brand/funding-chain-logo.png";
import SCVerifier from "assets/images/brand/scverifier-logo.png";
import HF from "assets/images/brand/hfFull.png";
import Rraft from "assets/images/brand/rraft-logo.png";

import { v4 as uuidv4 } from "uuid";

export const ProjectData = [
  {
    id: uuidv4(),
    title: "Funding Chain Logo",
    image: FundingChain,
    address: "https://www.fundingchain.io",
  },
  {
    id: uuidv4(),
    title: "HyperFuture Logo",
    image: HF,
    address: "https://www.hyperfuture.app",
  },
  {
    id: uuidv4(),
    title: "Rraft Logo",
    image: Rraft,
    address: "https://www.rraft.io",
  },
  {
    id: uuidv4(),
    title: "Smart Contract Verifier Logo",
    image: SCVerifier,
    address: "https://www.smartcontractverifier.com",
  },
];

export default ProjectData;
