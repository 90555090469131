import { Fragment } from "react";
import xIcon from "assets/images/social/xLogo-white.svg";
import fbLogo from "assets/images/social/fbLogo.svg";
import liLogo from "assets/images/social/liLogo.svg";
import { Row, Col, Image } from "react-bootstrap";

const Socials = () => {
  return (
    <Fragment>
      <Row>
        <Col className="d-flex justify-content-center">
          <a
            href="https://twitter.com/web3chefs"
            target="_blank"
            rel="noreferrer"
            className="text-muted text-primary-hover me-3">
            <Image
              src={xIcon}
              alt="Link to Decentralized Venture's X Profile"
              height={35}
            />
          </a>
          <a
            href="https://www.linkedin.com/company/decentralized-ventures"
            target="_blank"
            rel="noreferrer"
            className="text-muted text-primary-hover me-3">
            <Image
              src={liLogo}
              height={35}
              alt="Link to Decentralized Venture's Linkedin Profile"
            />
          </a>
        </Col>
      </Row>
    </Fragment>
  );
};
export default Socials;
