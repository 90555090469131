import React, { Fragment } from "react";
import FeaturesList from "./FeaturesList";
import HeroContent from "./HeroContent";

const About = () => {
  return (
    <Fragment>
      <HeroContent />
      <FeaturesList />
    </Fragment>
  );
};

export default About;
