import { Row } from "react-bootstrap";
import { mdiFileClock, mdiAccountGroup, mdiShieldLock } from "@mdi/js";
import FeatureTopIconCard from "components/main/FeatureTopIconCard";
import { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import dna from "../../assets/images/background/dna.mp4";

const Features3Columns = () => {
  const [ios, setIos] = useState(true);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  const features = [
    {
      id: 1,
      icon: mdiShieldLock,
      title: "Security and Privacy Practices",
      description: `Ensuring the utmost privacy and security of user data is paramount. We will never sell or exchange user data and we will always ensure to keep it secured by utilizing the latest technologies.`,
    },
    {
      id: 2,
      icon: mdiFileClock,
      title: "Trustless Applications",
      description: `Empowering applications with the reliability of smart contracts and distributed ledger technology, ensuring automatic execution when all conditions are met.`,
    },
    {
      id: 3,
      icon: mdiAccountGroup,
      title: "Equal Opportunity Internet",
      description: `Creating platforms accessible to people worldwide, irrespective of socioeconomic backgrounds, providing freedom from the constraints of the current financial system.`,
    },
  ];

  return (
    <Fragment>
      <div className="bg-black">
        <div
          style={{
            position: "relative",
            width: "100%",
            height: "100%",
            height: "fit-content",
            overflow: "hidden",
            minHeight: "100vh",
            alignContent: "center",
          }}>
          <div
            style={{
              position: "absolute",
              width: "100%",
              height: "100%",
              top: "0",
              left: "0",
              overflow: "hidden",
            }}>
            <video
              autoPlay={!ios}
              muted
              loop
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}>
              <source src={dna} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div
            style={{
              position: "relative",
              minHeight: "100vh",
              alignContent: "center",
              height: "100%",
              paddingTop: "200px",
              paddingBottom: "200px",
            }}
            className="moving-gradient-bg">
            <div className="text-center mb-6">
              <Row>
                <h2 className="display-3 mb-3 fw-bold text-white">
                  Our Core <span className="text-primary">Values</span>
                </h2>
              </Row>
            </div>
            <Row className="d-flex justify-content-center mb-5 px-5">
              {features.map((item, index) => {
                return <FeatureTopIconCard item={item} />;
              })}
            </Row>
            <Row className="d-flex justify-content-center">
              <Link
                style={{ width: "300px" }}
                to={"/about"}
                className="fs-3 fw-bold border-3 btn btn-outline-primary">
                Find Out More
              </Link>
            </Row>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Features3Columns;
