import { Fragment } from "react";
import xIcon from "assets/images/social/xLogo.svg";
import liLogo from "assets/images/social/liLogo.svg";
import { Row, Image } from "react-bootstrap";

const TeamSocials = ({ linkedin, twitter }) => {
  return (
    <Fragment>
      <Row>
        <div className="d-flex justify-content-center gap-3">
          {twitter && (
            <a
              href={twitter}
              target="_blank"
              rel="noreferrer"
              className="text-muted text-primary-hover">
              <Image src={xIcon} alt="X Icon" height={25} />
            </a>
          )}
          {linkedin && (
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className="text-muted text-primary-hover">
              <Image src={liLogo} height={25} alt="Linkedin Icon" />
            </a>
          )}
        </div>
      </Row>
    </Fragment>
  );
};
export default TeamSocials;
