import { Col, Image, Modal } from "react-bootstrap";
import LogoGif from "assets/images/loading/loading.gif";

const Spinner = ({ show }) => {
  return (
    <div>
      <Col lg={12}>
        <Modal fullscreen keyboard={false} show={show}>
          <Image className="m-auto" width={150} src={LogoGif}></Image>
        </Modal>
      </Col>
    </div>
  );
};
export default Spinner;
