import { Col, Row, Image } from "react-bootstrap";
import { Fragment, useState, useEffect } from "react";
import networkedWorld from "../../assets/images/background/networked-world.mp4";

const HeroContent = () => {
  const [ios, setIos] = useState(true);

  useEffect(() => {
    setIos(/iPhone|iPad/i.test(navigator.userAgent));
  }, []);

  return (
    <Fragment>
      <div>
        <div className="bg-black">
          <div
            style={{
              position: "relative",
              width: "100%",
              height: "100%",
              height: "fit-content",
              overflow: "hidden",
              minHeight: "100vh",
              alignContent: "center",
            }}>
            <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                top: "0",
                left: "0",
                overflow: "hidden",
              }}>
              <video
                autoPlay={!ios}
                muted
                loop
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}>
                <source src={networkedWorld} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
            <div
              style={{
                position: "relative",
                zIndex: "0",
                minHeight: "100vh",
                alignContent: "center",
                height: "100%",
                backgroundColor: "#00000079",
                paddingTop: "100px",
                paddingBottom: "100px",
              }}>
              <h1 className="fw-bold hero-txt text-white">
                <span className="text-primary">Revolutionizing</span> the
                everyday internet with{" "}
                <span className="text-primary">Decentralization</span>
              </h1>
              <p className="text-white hero-lead">
                We’re building the best next-generation full stack Web 3.0
                applications that will seamlessly change how everyday tasks are
                completed on the internet with the use of smart contracts and
                distributed ledger technology.
              </p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
export default HeroContent;
