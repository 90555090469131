import { Col, Row, Form, Button, Image, Container } from "react-bootstrap";
import { FormSelect } from "components/contact/FormSelect";
import Spinner from "./Spinner.jsx";
import ReCAPTCHA from "react-google-recaptcha";
import { useState, useEffect } from "react";
import validator from "validator";
import axios from "axios";

const ContactForm = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [contactReason, setContactReason] = useState("");
  const [message, setMessage] = useState("");
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [robotCheck, setRobotCheck] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const reasons = [
    { value: "Consultation", label: "Consultation" },
    { value: "Development", label: "Development" },
    { value: "Other", label: "Other" },
  ];
  let [captchaError, setCaptchaError] = useState();
  function reCaptcha(value) {
    if (value !== undefined) {
      setRobotCheck(true);
    }
  }

  useEffect(() => {
    document.getElementById("thanks").style = "display: none;";
    // document.getElementById("form").style = "display: none;";
  });

  function submitHandler(event) {
    event.preventDefault();
    let firstNameGood = false;
    let lastNameGood = false;
    let emailGood = false;
    let phoneNumberGood = false;
    let contactReasonGood = false;
    let messageGood = false;
    let robotCheckGood = false;
    const restrictedCharacters = /[$\\"']/g;

    if (!restrictedCharacters.test(firstName) && firstName.length > 0) {
      firstNameGood = true;
      document.getElementById("firstName").classList = "form-control";
    } else {
      firstNameGood = false;
      document.getElementById("firstName").classList =
        "form-control is-invalid";
    }
    if (!restrictedCharacters.test(lastName) && lastName.length > 0) {
      lastNameGood = true;
      document.getElementById("lastName").classList = "form-control";
    } else {
      lastNameGood = false;
      document.getElementById("lastName").classList = "form-control is-invalid";
    }
    if (validator.isEmail(email)) {
      emailGood = true;
      document.getElementById("email").classList = "form-control";
    } else {
      emailGood = false;
      document.getElementById("email").classList = "form-control is-invalid";
    }
    if (validator.isMobilePhone(phoneNumber) && phoneNumber.length >= 10) {
      phoneNumberGood = true;
      document.getElementById("phoneNumber").classList = "form-control";
    } else {
      phoneNumberGood = false;
      document.getElementById("phoneNumber").classList =
        "form-control is-invalid";
    }
    if (!restrictedCharacters.test(message) && message.length > 5) {
      messageGood = true;
      document.getElementById("message").classList = "form-control";
    } else {
      messageGood = false;
      document.getElementById("message").classList = "form-control is-invalid";
    }
    if (contactReason !== "") {
      contactReasonGood = true;
      document.getElementById("contactReason").classList = "form-control";
    } else {
      contactReasonGood = false;
      document.getElementById("contactReason").classList =
        "form-control is-invalid";
    }
    if (robotCheck) {
      robotCheckGood = true;
      setCaptchaError("");
    } else {
      robotCheckGood = false;
      setCaptchaError("Please complete reCAPTCHA before submitting.");
    }
    if (
      firstNameGood &&
      lastNameGood &&
      emailGood &&
      phoneNumberGood &&
      contactReasonGood &&
      messageGood &&
      robotCheckGood
    ) {
      let data = {
        firstName,
        lastName,
        email,
        phoneNumber,
        contactReason,
        message,
      };
      handleShow();
      document.getElementById("button").disabled = true;
      axios
        .post(
          "https://dv-api-e55b1e7a73db.herokuapp.com/decentralized-ventures-contact-form",
          data
        )
        .then((res) => {
          if (res.data === "sent") {
            document.getElementById("form").style = "display: none;";
            handleClose();
            document.getElementById("thanks").style = "display: block;";
          } else {
            setError("Something went wrong, please try again.");
            document.getElementById("button").disabled = false;
            handleClose();
          }
        })
        .catch((err) => {
          setError("Something went wrong, please try again.");
          document.getElementById("button").disabled = false;
          handleClose();
          console.error(err);
        });
    }
  }

  return (
    <div className="mt-5">
      <div className="px-4 px-xl-10 py-8 py-lg-0">
        {/* form section */}
        <div>
          <Container>
            <Form id="form" noValidate onSubmit={submitHandler} className="">
              <p className="validationError">{error}</p>
              <Row>
                {/* First Name */}
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">First Name:</Form.Label>
                    <Form.Control
                      id="firstName"
                      onChange={(e) => {
                        setFirstName(e.target.value);
                      }}
                      maxLength={20}
                      type="text"
                      placeholder="First Name"
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Last Name */}
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Last Name:</Form.Label>
                    <Form.Control
                      id="lastName"
                      onChange={(e) => {
                        setLastName(e.target.value);
                      }}
                      type="text"
                      placeholder="Last Name"
                      maxLength={20}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Email */}
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Email:</Form.Label>
                    <Form.Control
                      id="email"
                      onChange={(e) => {
                        setEmail(e.target.value);
                      }}
                      type="email"
                      placeholder="Email"
                      maxLength={50}
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Phone Number */}
                <Col md={6} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">
                      Phone Number:
                    </Form.Label>
                    <Form.Control
                      id="phoneNumber"
                      onChange={(e) => {
                        setPhoneNumber(e.target.value);
                      }}
                      type="number"
                      placeholder="Phone"
                      required
                    />
                  </Form.Group>
                </Col>

                {/* Contact Reason */}
                <Col md={12} sm={12}>
                  <Form.Group
                    onChange={(e) => {
                      setContactReason(e.target.value);
                    }}
                    className="mb-3">
                    <Form.Label className="text-white">
                      Contact Reason:
                    </Form.Label>
                    <Form.Control
                      id="contactReason"
                      as={FormSelect}
                      placeholder="Select"
                      options={reasons}
                      required
                    />
                  </Form.Group>
                </Col>
                {/* Messages */}
                <Col md={12} sm={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="text-white">Message:</Form.Label>
                    <Form.Control
                      id="message"
                      onChange={(e) => {
                        setMessage(e.target.value);
                      }}
                      as="textarea"
                      placeholder="Messages"
                      rows={3}
                      maxLength={500}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3">
                    <div
                      className={` ${
                        captchaError
                          ? "border border-1 border-danger"
                          : "border-none"
                      }`}
                      style={{ width: "fit-content", borderRadius: "4px" }}>
                      <div
                        style={{
                          marginRight: "-1px",
                          marginBottom: "-2px",
                          alignContent: "center",
                          height: "100%",
                        }}>
                        <ReCAPTCHA
                          sitekey="6LcuWeceAAAAAPcVZ7Kyesu6DmN5ASlWrsLSqFh9"
                          onChange={reCaptcha}
                        />
                      </div>
                    </div>
                    {captchaError && (
                      <p className="text-danger mb-0">
                        Please check the reCaptcha box.
                      </p>
                    )}
                  </Form.Group>
                </Col>

                {/* button */}
                <Col md={12} sm={12}>
                  <Button
                    id="button"
                    variant="primary"
                    type="submit"
                    className="mb-5">
                    Submit
                  </Button>
                </Col>
              </Row>
            </Form>
          </Container>
          <Container id="thanks" className="hide">
            <Row className="align-items-center mx-auto lg-min-vh-100">
              <Col md={{ span: 12, offset: 5 }} sm={{ span: 12, offset: 5 }}>
                <div>
                  <h2 className="display-5 fw-bold text-primary">Thank you!</h2>
                  <p className="lead justify text-dark">
                    We will be in touch soon.
                  </p>
                </div>
              </Col>
            </Row>
            <Spinner show={show} />
          </Container>
        </div>
      </div>
    </div>
  );
};
export default ContactForm;
