// import node module libraries
import { Fragment, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Image, Navbar, Nav, Container } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import Logo from "assets/images/brand/logo-nobackground-1000.png";
import NavbarDefaultRoutes from "routes/NavbarDefault";
import NavDropdownMain from "components/navbar/NavDropdownMain";
import Socials from "common/socials/Socials";

const NavbarDefault = ({ headerstyle, login }) => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1224px)",
  });
  const isLaptop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  const [expandedMenu, setExpandedMenu] = useState(false);

  return (
    <Fragment>
      <Navbar
        onToggle={(collapsed) => setExpandedMenu(collapsed)}
        expanded={expandedMenu}
        expand="lg"
        style={{
          position: "fixed",
          top: "0",
          width: "100%",
          zIndex: "1000",
        }}
        className={`navbar px-4 bg-trans`}>
        <Container fluid className="px-0 ps-0">
          <Navbar.Brand as={Link} to="/" onClick={() => setExpandedMenu(false)}>
            <Image height={80} src={Logo} alt="Decentralized Ventures Logo" />
          </Navbar.Brand>
          <div
            className={`navbar-nav navbar-right-wrap ms-auto d-lg-none nav-top-wrap ${
              login ? (isDesktop || isLaptop ? "d-none" : "d-flex") : "d-none"
            }`}></div>
          <Navbar.Toggle aria-controls="basic-navbar-nav">
            <span className="icon-bar top-bar mt-0"></span>
            <span className="icon-bar middle-bar"></span>
            <span className="icon-bar bottom-bar"></span>
          </Navbar.Toggle>
          <Navbar.Collapse id="basic-navbar-nav navbar-middle">
            <Nav className="ms-3">
              {NavbarDefaultRoutes.map((item, index) => {
                if (item.children === undefined) {
                  return (
                    <Nav.Link
                      onClick={() => setExpandedMenu(false)}
                      className="me-2"
                      key={index}
                      as={Link}
                      to={item.link}>
                      {item.menuitem}
                    </Nav.Link>
                  );
                } else {
                  return (
                    <NavDropdownMain
                      item={item}
                      key={index}
                      onClick={() => setExpandedMenu(false)}
                    />
                  );
                }
              })}
            </Nav>
            <Nav className="navbar-nav navbar-right-wrap ms-auto">
              <Socials />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

// Specifies the default values for props
NavbarDefault.defaultProps = {
  headerstyle: "navbar-default",
  login: false,
};

// Typechecking With PropTypes
NavbarDefault.propTypes = {
  headerstyle: PropTypes.string,
  login: PropTypes.bool,
};

export default NavbarDefault;
