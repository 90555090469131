// import node module libraries
import { Image, Card } from "react-bootstrap";

const ProjectCard = ({ item }) => {
  return (
    <div
      style={{
        maxWidth: "300px",
        maxHeight: "300px",
      }}>
      <Card
        style={{ alignContent: "center", height: "100%" }}
        href={item.address}
        className={`mx-2 card-hover`}>
        <a target="_blank" rel="noreferrer" href={item.address}>
          <Image fluid src={item.image} alt={item.title} className="p-3" />
        </a>
      </Card>
    </div>
  );
};

export default ProjectCard;
