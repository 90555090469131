import { v4 as uuid } from "uuid";

const NavbarDefault = [
  {
    id: uuid(),
    menuitem: "Projects",
    link: "/projects",
  },
  {
    id: uuid(),
    menuitem: "About",
    link: "/about",
  },
  {
    id: uuid(),
    menuitem: "Contact Us",
    link: "/contact-us",
    //   },
    // ],
  },
];

export default NavbarDefault;
